<template>
  <div class="right-content">
    <Breadcrumb :crumb="crumb" addpath="/Basicknowledge/add" :showButton="false"></Breadcrumb>
    <el-form>
      <el-form-item label="关键字" prop="name">
        <el-input placeholder="请输入" clearable size="small" style="width: 240px" v-model="keyword" @change="getKeyword">
          <el-button slot="append" class="searchbtn" @click="searchput">搜索</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <div style="display: flex; justify-content: space-between; margin-bottom: 10px">
     
      <el-button @click="add">添加</el-button>
    </div>

    <el-table :data="tableData" style="width: 100%" max-height="550" v-loading="loading">
      <el-table-column fixed prop="id" label="ID"> </el-table-column>
      <el-table-column prop="title" label="标题"> </el-table-column>
      <el-table-column label="图片">
        <template slot-scope="scope">
          <el-image style="width: 100px; height: 100px" :src="'/api' + scope.row.image" :fit="scale - down"></el-image>
        </template>
      </el-table-column>
    
      <el-table-column label="是否可用">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.isEnable" active-color="#13ce66" inactive-color="#ff4949"
            @change="changeStatus(scope.row, scope.$index)">
          </el-switch>
        </template>
      </el-table-column>

      <el-table-column label="小程序链接">
        <template slot-scope="scope">
          /pages/shuoming/detail?id={{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="添加时间">
        <template slot-scope="scope">
          {{ scope.row.createdAt | formatDate("yyyy-M-d H:m:s") }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small">
            移除
          </el-button>
          <el-button @click.native.prevent="edit(scope.$index)" type="text" size="small">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="total" @current-change="getList"></el-pagination>
    <el-dialog title="详细信息" :visible.sync="updatePageVisible" width="80%">
      <updatePage :id="editId" @closeUpdatePate="closeUpdatePate"></updatePage>
    </el-dialog>
    <el-dialog title="添加" :visible.sync="addPageVisible" width="80%">
      <addPage @closeAddPate="closeAddPate"></addPage>
    </el-dialog>
  </div>
</template>

<script>
import operate from "../../api/api.js";
import updatePage from "./edit.vue";
import addPage from "./add.vue";
export default {
  components: {
    updatePage,
    addPage,
  },
  data() {
    return {
      loading: false,
      keyword: "",
      total: 0,
      pageIndex: 1,
      updatePageVisible: false,
      addPageVisible: false,
      tableData: [],
      crumb: ["源测说明", "列表"],
      editId:0
    };
  },
  methods: {
    closeUpdatePate() {
      this.updatePageVisible = false;
    },
    closeAddPate() {
      this.addPageVisible = false;
    },
    changeStatus(e) {
      operate
        .editBasicknowledge({ id: e.id, status: e.status ? 0 : 1 }, "post")
        .then((response) => {
          if (response.code == "0000") {
            this.$message({
              message: response.msg,
              type: "success",
              onClose: function () {
                window.location.reload();
              },
            });
          }
        });
    },

    deleteRow(index, rows) {
      let _info = this.tableData[index];
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        operate.deleteBasicknowledge(_info.id).then((response) => {
          if (response.code == "0000") {
            this.$message({
              message: response.msg,
              type: "success",
              onClose: function () {
                window.location.reload();
              },
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    searchput() {
      this.getList(1);
    },
    getKeyword() { },
    add() {
      this.addPageVisible = true;
    },
    edit(index) {
      let id = this.tableData[index].id;
      this.editId = id;
      console.log(this.editId);
      this.updatePageVisible = true;
    },
    getList(p) {
      let data = {};
      this.pageIndex = p
      if (this.keyword.length > 0) {
        data = { pageIndex: p, name: this.keyword,type:5 };
      } else {
        data = { pageIndex: p ,type:5 };
      }
      operate.getBasicknowledgeList(data).then((response) => {
        this.tableData = response.data;
        this.tableData.forEach(function (item, index) {
          if (item.status == 1) {
            item.isEnable = true;
          } else {
            item.isEnable = false;
          }
        });
        this.total = response.total;
      });
    },
  },
  mounted() {
    operate.getBasicknowledgeList({ pageIndex: 1,type:5 }).then((response) => {
      this.tableData = response.data;
      this.tableData.forEach(function (item, index) {
        if (item.status == 1) {
          item.isEnable = true;
        } else {
          item.isEnable = false;
        }
      });
      this.total = response.total;
    });
  },
};
</script>